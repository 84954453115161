import { ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { Toast } from 'vant'
import CryptoJS from 'crypto-js'
import { useStore } from '@/store'
import { Goods, LandingBanner } from '../types'
import { goOldAuth } from '@/utils/adapter'
import { randomStr, setLocalStorage } from '@/utils'
import { getDiscountLandingInfo, getDiscountLandingFcode } from '@/api/couponLanding'
import { ResponseCode } from '@/constants/http'
import { transformUrl } from '@/utils'

let timer: ReturnType<typeof setInterval>

export default function () {
  const { query } = useRoute()
  const code = query.code as string
  const store = useStore()

  // 是否活动不存在
  const notExist = ref(false)
  const id = ref('')
  // 活动是否过期
  const overdue = ref(false)
  const landingName = ref('')
  const bannerUrl = ref('')
  const landingBanner = ref<LandingBanner[]>([])
  const goodsList = ref<Goods[]>([])
  const backgroundColor = ref('')
  const startTime = ref('')
  const endTime = ref('')
  const isTime = ref(0) // 0不计时 1计时
  const secondKillTime = ref(0)

  const hourLeft = computed(() => fixDigits(Math.floor(secondKillTime.value / 3600)))
  const minuteLeft = computed(() => fixDigits(Math.floor((secondKillTime.value / 60) % 60)))
  const secondLeft = computed(() => fixDigits(Math.floor(secondKillTime.value % 60)))

  // 修复位数，比如 5秒 => 05秒
  const fixDigits = (num: number): string => {
    return num < 10 ? '0' + num : '' + num
  }

  const fetchInfo = () => {
    getDiscountLandingInfo(code)
      .then((res) => {
        console.log('res', res)
        id.value = res.data.data.id
        overdue.value = res.data.data.overdue
        bannerUrl.value = res.data.data.bannerUrl
        landingBanner.value = res.data.data.landingBanner
        landingName.value = res.data.data.landingName
        startTime.value = res.data.data.startTime
        endTime.value = res.data.data.endTime
        backgroundColor.value = res.data.data.backgroundColor
        goodsList.value = res.data.data.goodsList
        isTime.value = res.data.data.isTime
        secondKillTime.value = res.data.data.secondKillTime

        if (isTime.value === 1 && secondKillTime.value) {
          startCountdown()
        }

        document.title = `${landingName.value}-讯飞商城`
      })
      .catch((error) => {
        console.log('error', error)
        if (error?.data?.code === ResponseCode.ActivityNotExist) {
          // 活动不存在
          notExist.value = true
        }
      })
  }

  const startCountdown = () => {
    timer = setInterval(() => {
      if (secondKillTime.value < 1) {
        endCountdown()
      } else {
        secondKillTime.value = secondKillTime.value - 1
      }
    }, 1000)
  }

  const endCountdown = () => {
    clearInterval(timer)
    overdue.value = true
  }

  const buy = (goods: Goods) => {
    if (!store.state.auth.token) {
      console.log('未登录')
      // goLogin()
      goOldAuth()
      return
    }
    if (goods.whetherSoldOut) {
      return
    }
    console.log('立即购买')
    getDiscountLandingFcode({ landingCode: code, codeId: goods.fcodeId }).then((res) => {
      console.log('res', res)
      console.log('跳转商详', res.data.data, goods.goodsId)
      if (res.data.code == 0) {
        const { origin, host } = location
        const f_key = randomStr(CryptoJS)
        // 以 {f_key: f_code} 键值对的形式缓存 f_code
        setLocalStorage(f_key, res.data.data)
        // 如果地址栏有boc这个查询参数，则说明是从中国银行APP环境内跳转过来的，需要跳转到中国银行环境对应的页面
        const boc = transformUrl(location.href).boc
        const commonPath = `/h5${boc ? '/boc.html' : ''}#/product?id=${goods.goodsId}&f_key=${f_key}`
        if (host === 'mall.iflysec.com') {
          // 测试环境
          location.href = `${origin}/ifly-mall/ecshop${commonPath}`
        } else {
          // 正式环境
          location.href = `${origin}${commonPath}`
        }
      } else {
        Toast(res.data.msg)
      }
    })
  }

  const bannerJump = () => {
    if (landingBanner.value.length) {
      location.href = landingBanner.value[0].bannerUrl || ''
    }
  }

  onMounted(() => {
    fetchInfo()
  })

  return {
    notExist,
    id,
    overdue,
    bannerUrl,
    landingBanner,
    goodsList,
    backgroundColor,
    startTime,
    endTime,
    isTime,
    secondKillTime,
    hourLeft,
    minuteLeft,
    secondLeft,
    fetchInfo,
    buy,
    bannerJump,
  }
}
