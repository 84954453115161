
import { defineComponent } from 'vue'
import { Button } from 'vant'
import useInfo from './model/useInfo'

export default defineComponent({
  name: 'DiscountLanding',

  components: {
    [Button.name]: Button,
  },

  setup() {
    const {
      notExist,
      id,
      overdue,
      bannerUrl,
      landingBanner,
      goodsList,
      backgroundColor,
      startTime,
      endTime,
      isTime,
      secondKillTime,
      hourLeft,
      minuteLeft,
      secondLeft,
      fetchInfo,
      buy,
      bannerJump,
    } = useInfo()

    return {
      notExist,
      id,
      overdue,
      bannerUrl,
      landingBanner,
      goodsList,
      backgroundColor,
      startTime,
      endTime,
      isTime,
      secondKillTime,
      hourLeft,
      minuteLeft,
      secondLeft,
      fetchInfo,
      buy,
      bannerJump,
    }
  },
})
