<template>
  <template v-if="!notExist">
    <div v-if="overdue" class="empty-wrapper">
      <empty message="当前活动已过期~" />
    </div>
    <div v-else class="discount-landing">
      <div class="main" :style="{ backgroundColor: backgroundColor }">
        <div class="banner" @click="bannerJump">
          <img
            :src="(landingBanner && landingBanner[0] && landingBanner[0].bannerImgUrl) || ''"
            alt=""
          />
        </div>

        <div v-if="isTime == 1" class="countdown">
          <div class="countdown-l">
            <img :src="require('@/assets/icons/stopwatch.png')" alt="" />
            <span>活动剩余</span>
          </div>
          <div class="countdown-r">
            <span>{{ hourLeft }}小时</span>
            <span>{{ minuteLeft }}分钟</span>
            <span>{{ secondLeft }}秒</span>
          </div>
        </div>

        <div class="goods-list">
          <div v-for="(goods, goodsIndex) in goodsList" :key="goodsIndex" class="goods-item">
            <div class="goods-item__inner">
              <div class="goods-item__thumb-box">
                <img class="goods-item__thumb" :src="goods.goodsThumb" alt="" />
                <div v-if="goods.whetherSoldOut" class="goods-item__thumb-stamp">已抢光</div>
              </div>
              <div class="goods-item__cont" @click="buy(goods)">
                <div class="goods-item__title">{{ goods.goodsName }}</div>
                <div class="goods-item__desc">{{ goods.goodsBrief }}</div>
                <div class="goods-item__price-label">专享价</div>
                <div class="goods-item__price-wrap">
                  <span class="goods-item__price">￥{{ goods.price }}</span>
                  <span class="goods-item__price-old">￥{{ goods.marketPrice }}</span>
                </div>
                <div
                  class="goods-item__btn"
                  :class="{ 'goods-item__btn--disabled': goods.whetherSoldOut }"
                  >{{ goods.whetherSoldOut ? '已抢光' : '立即购买' }}</div
                >
              </div>
            </div>
            <!-- <div v-if="goods.isRec" class="goods-item__badge">荐</div> -->
          </div>
        </div>
      </div>

      <div v-if="goodsList.length > 0" class="intro">
        <div class="intro-title">使用说明</div>
        <div class="intro-list">
          <div class="intro-list-item">登录后方可立即购买，数量有限，售完即止</div>
          <!-- <div class="intro-list-item"
            >2. 如果个人无法购买时，可至<a class="intro-list-item__link" href=""> 个人中心-我的F码 </a
            >查看是否有未使用的特权F码，可继续使用</div
          > -->
          <!-- <div class="intro-list-item">2. 数量有限，售完即止</div> -->
        </div>
        <template v-if="startTime && endTime">
          <div class="intro-title hasgap">活动期限</div>
          <div class="intro-list">
            <div class="intro-list-item">{{ startTime }}-{{ endTime }}</div>
          </div>
        </template>
      </div>
    </div>
  </template>
  <div v-else class="empty-wrapper">
    <empty message="当前活动不存在~" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Button } from 'vant'
import useInfo from './model/useInfo'

export default defineComponent({
  name: 'DiscountLanding',

  components: {
    [Button.name]: Button,
  },

  setup() {
    const {
      notExist,
      id,
      overdue,
      bannerUrl,
      landingBanner,
      goodsList,
      backgroundColor,
      startTime,
      endTime,
      isTime,
      secondKillTime,
      hourLeft,
      minuteLeft,
      secondLeft,
      fetchInfo,
      buy,
      bannerJump,
    } = useInfo()

    return {
      notExist,
      id,
      overdue,
      bannerUrl,
      landingBanner,
      goodsList,
      backgroundColor,
      startTime,
      endTime,
      isTime,
      secondKillTime,
      hourLeft,
      minuteLeft,
      secondLeft,
      fetchInfo,
      buy,
      bannerJump,
    }
  },
})
</script>

<style lang="less" scoped>
// @TODO: why style-resources-loader doesn't work?
@import url(~@/assets/styles/mixins.less);
@primary: #f34142;

.discount-landing {
  min-height: 100vh;
  background: #fcfcfc;
  .main {
    padding-top: 42px;
    padding-bottom: 20px;
    background: #f4f4f2;
    .banner {
      width: 690px;
      // height: 317px;
      margin: 0 auto 20px;
      img {
        width: 100%;
      }
    }

    .countdown {
      display: flex;
      align-items: center;
      width: 690px;
      height: 80px;
      margin: 20px auto;
      font-size: 28px;
      color: #fff;
      background: url('~@/assets/images/bg_countdown.png') no-repeat center;
      background-size: 100%;
      &-l {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        width: 220px;
        height: 80px;
        img {
          width: 24px;
          margin-right: 10px;
        }
      }
      &-r {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        box-sizing: border-box;
        // width: 470px;
        height: 80px;
        padding: 0 68px;
        span {
          position: relative;
          // width: 33.333333%;
          line-height: 80px;
          &:nth-child(1) {
            // width: 150px;
            padding-right: 20px;
            text-align: right;
          }
          &:nth-child(2) {
            width: 130px;
            text-align: center;
            &::before,
            &::after {
              position: absolute;
              display: block;
              content: '';
              width: 0;
              height: 32px;
              border-right: 1px dashed #fff;
              top: 50%;
              transform: translateY(-50%);
              opacity: 0.5;
            }
            &::before {
              left: 0;
            }
            &::after {
              right: 0;
            }
          }
          &:nth-child(3) {
            // width: 102px;
            padding-left: 20px;
            text-align: center;
          }
        }
      }
    }

    .goods-list {
      width: 690px;
      margin: 0 auto;
      .goods-item {
        position: relative;
        height: 300px;
        margin-bottom: 20px;
        background-color: #fff;
        &__inner {
          display: flex;
          align-items: center;
          height: 300px;
          overflow: hidden;
        }
        &__thumb-box {
          position: relative;
          margin-right: 45px;
        }
        &__thumb {
          width: 300px;
          height: 300px;
        }
        &__thumb-stamp {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 142px;
          height: 142px;
          line-height: 142px;
          border-radius: 50%;
          text-align: center;
          font-size: 24px;
          background-color: #000;
          opacity: 0.7;
          color: #fff;
          &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            content: '';
            width: 110px;
            height: 110px;
            border-radius: 50%;
            border: 1px dashed rgba(255, 255, 255, 0.7);
          }
        }
        &__cont {
          display: flex;
          flex-direction: column;
          width: 345px;
          height: 100%;
          padding: 40px 20px 20px 0;
          box-sizing: border-box;
        }
        &__title {
          line-height: 34px;
          font-size: 24px;
          font-weight: 500;
          color: #333;
          .single-ellipsis();
        }
        &__desc {
          // margin-bottom: 16px;
          margin-bottom: 26px;
          line-height: 30px;
          font-size: 20px;
          color: #666;
          .single-ellipsis();
        }
        &__price-label {
          line-height: 20px;
          font-size: 20px;
          color: @primary;
        }
        &__price-wrap {
          display: flex;
          align-items: baseline;
          height: 50px;
        }
        &__price {
          font-size: 36px;
          color: @primary;
        }
        &__price-old {
          margin-left: 10px;
          font-size: 20px;
          color: #666;
          text-decoration: line-through;
        }
        &__btn {
          // display: flex;
          // justify-content: center;
          // align-items: center;
          // width: 150px;
          // height: 46px;
          align-self: flex-start;
          display: inline-block;
          padding: 10px 75px;
          margin-top: auto;
          border-radius: 100px;
          font-size: 26px;
          background-color: @primary;
          color: #fff;
          &--disabled {
            background-color: #999;
          }
        }
        &__badge {
          position: absolute;
          top: 0;
          right: 18px;
          width: 50px;
          height: 56px;
          line-height: 56px;
          border-radius: 0 0 28px 28px;
          text-align: center;
          font-size: 24px;
          color: #fff;
          background-color: @primary;
        }
      }
    }
  }

  .intro {
    margin-top: 20px;
    background-color: #fcfcfc;
    padding: 30px 0;
    &-title {
      width: 690px;
      margin: 0 auto;
      font-size: 24px;
      margin-bottom: 10px;
      color: #666;
      &.hasgap {
        margin-top: 40px;
      }
    }
    &-list {
      width: 690px;
      margin: 0 auto;
      &-item {
        position: relative;
        line-height: 34px;
        margin-bottom: 5px;
        font-size: 20px;
        color: #999;
        &__link {
          color: #a8a8a8;
        }
      }
    }
  }
}

.empty-wrapper {
  height: 100vh;
  padding-top: 126px;
  background: #fff;
}
</style>
